import { topUpApi } from '../../infrastructure/api';
import {
	readPaymentMethod,
	readPromocodeInfo,
	readSumData,
} from '../../infrastructure/store';
import { pageDrawers } from '~/src/features/page-drawers';
import { useOpenTerminalPage } from '~/src/shared/lib';
import {useSidebarStore} from "~/src/widgets/terminal/store";

export const useSubmitTopUp = () => {
	const sendTopUp = topUpApi.useSendTopUp();
	const openTerminalPage = useOpenTerminalPage();

	return async () => {
		const paymentMethod = readPaymentMethod();
		const promocodeInfo = readPromocodeInfo();
		const sumData = readSumData();
		const sidebarStore = useSidebarStore();
		if (!paymentMethod || !sumData) {
			return;
		}

		const result = await sendTopUp({
			paymentMethod,
			sumData,
			promocode: promocodeInfo?.value || '',
		});

		if (result.isRight()) {
			window.open(result.value, '_blank');
			sidebarStore.closeDrawer();
			openTerminalPage();
		}
	};
};
