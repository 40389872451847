import type { TopUpData } from '../../core/types';
import type { Response } from '~/src/shared/lib';
import { useApi } from '@/src/services/network';

export type TopUpDTO = Response<Url>;

export const toDomain = (data: TopUpDTO) => data.result;

export const useSendTopUp = () => {
	const api = useApi();

	return async (data: TopUpData) =>
		(
			await api<TopUpDTO>('/Transactions/replenish/', {
				method: 'post',
				body: {
					paysystem: data.paymentMethod.id,
					sum: data.sumData.sum,
					promocode: data.promocode,
				},
			})
		).mapRight(toDomain);
};
