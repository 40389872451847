import { topUpApi } from '../../infrastructure/api';
import { savePromocodeInfo } from '../../infrastructure/store';
import type { Promocode } from '../types';

export const useCheckPromocode = () => {
	const checkPromocode = topUpApi.useCheckPromocode();

	return async (promocode: Promocode) => {
		const result = await checkPromocode(promocode);
		if (result.isRight()) {
			savePromocodeInfo({
				value: promocode,
				percent: result.value.percent,
			});
		}
		else {
			savePromocodeInfo({
				errorMessage: result.value.message,
				type: 'error',
			});
		}
	};
};
