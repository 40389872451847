import type { ProcessedPromocodeData, PromocodeInfo } from '../types';

const getBonusSum = (sum: number, percent: number) => (sum * percent) / 100;

export const getProcessedPromocodeData = (
	sum: number,
	promocodeInfo: PromocodeInfo | null,
): ProcessedPromocodeData => {
	if (!promocodeInfo || promocodeInfo.type === 'error') {
		return {
			type: 'base',
			recieveSum: undefined,
			bonusSum: undefined,
			paymentSum: sum,
			percent: undefined,
		};
	}
	else {
		const bonusSum = getBonusSum(sum, promocodeInfo.percent as number);

		return {
			type: 'withPercent',
			recieveSum: bonusSum + sum,
			bonusSum,
			paymentSum: sum,
			percent: promocodeInfo.percent as number,
		};
	}
};
