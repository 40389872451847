import type { Promocode } from '../../core/types';
import { useApi } from '@/src/services/network';
import type { Response } from '~/src/shared/lib';

type CheckPromocodeDTO = Response<{
	name: string;
	code: Promocode;
	percent: number;
}>;

export const useCheckPromocode = () => {
	const api = useApi();

	return async (promocode: Promocode) =>
		(
			await api<CheckPromocodeDTO>(`/Promocodes/check/`, {
				method: 'post',
				body: {
					promocode,
				},
			})
		).mapRight(r => r.result);
};
